<template>
  <!-- BEGIN: Main Menu-->
  <div>
    <div
      v-if="show"
      class="main-menu menu-fixed menu-accordion menu-shadow expanded menu-dark"
      :style="openMenu ? 'transform: translate3d(260px, 0, 0);width: 260px;left: -260px;opacity:1;' : ''"
      data-scroll-to-active="true"
    >
      <div class="navbar-header">
        <ul class="nav navbar-nav flex-row">
          <li class="nav-item me-auto">
            <a
              class="navbar-brand"
              href="#"
            >
              <img
                :src="require('@/assets/images/logo/logo-icrea-white-menu.svg')"
                alt="ICREA"
              >
            </a>
          </li>
          <li class="nav-item nav-toggle">
            <a
              class="nav-link modern-nav-toggle pe-0"
              data-bs-toggle="collapse"
            >
              <i
                class="d-block d-xl-none text-primary toggle-icon font-medium-4"
                data-feather="chevron-down"
              />
              <i
                class="d-none d-xl-block collapse-toggle-icon font-medium-4 text-primary"
                data-feather="disc"
                data-ticon="disc"
              />
            </a>
          </li>
        </ul>
      </div>
      <div class="shadow-bottom" />
      <div class="main-menu-content overflow-auto mb-5">
        <ul
          id="main-menu-navigation"
          class="navigation navigation-main"
          data-menu="menu-navigation"
        >
          <!-- <li class="nav-item"> Dashboard
            <a
              class="d-flex align-items-center"
              href="dashboard.php"
            >
              <i data-feather="home" /><span
                class="menu-title text-truncate"
              >Dashboard</span>
            </a>
          </li> -->
          <li class="nav-item"> <!-- Alerts -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'alerts' })"
            >
              <i data-feather="bell" /><span
                class="menu-title text-truncate"
              >Alerts & notifications</span>
            </a>
          </li>
          <li class="nav-item has-sub"> <!-- Calls -->
            <a
              v-b-toggle="`collapseMenu-2`"
              class="d-flex align-items-center"
            >
              <i data-feather="message-square" /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >Calls</span>
            </a>

            <b-collapse :id="`collapseMenu-2`">
              <ul class="menu-content ps-2">
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.calls.senior.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Senior Call"
                    >ICREA call</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.calls.promotion.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Senior Call"
                    >Promotion</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.calls.academy.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Senior Call"
                    >Academia call</span>
                  </a>
                </li>
                <!-- <li class="nav-item has-sub">
                  <a
                    v-b-toggle="`collapseMenu-academia`"
                    class="d-flex align-items-center"
                  >
                    <i class="fake-circle" /><span
                      class="menu-title text-truncate"
                      data-i18n="Academia"
                    >Academia</span>
                  </a>
                  <b-collapse :id="`collapseMenu-academia`">
                    <ul class="menu-content">
                      <li>
                        <a
                          id="field-academia-calls"
                          class="d-flex align-items-center"
                          @click="$router.push({ name: 'admin.calls.academy.index' })"
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Academia calls"
                          >Academia calls</span>
                        </a>
                        <b-tooltip
                          placement="topleft"
                          boundary="document"
                          triggers="hover"
                          :target="`field-academia-calls`"
                          title="Academia calls"
                        />
                      </li>
                      <li>
                        <a
                          class="d-flex align-items-center"
                          @click="$router.push({ name: 'admin.calls.academy_awarded.index' })"
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Awardees"
                          >Awardees</span>
                        </a>
                      </li>
                      <li>
                        <a
                          id="field-extension-requests"
                          class="d-flex align-items-center"
                          @click="$router.push({ name: 'admin.calls.extension-forms.index' })"
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Awarded"
                          >Request extension</span>
                          <b-tooltip
                            placement="right"
                            boundary="document"
                            triggers="hover"
                            :target="`field-extension-requests`"
                            title="Request extensions"
                          />
                        </a>
                      </li>
                    </ul>
                  </b-collapse>
                </li>
                <li class="has-sub">
                  <a
                    v-b-toggle="`collapseMenu-promotion`"
                    class="d-flex align-items-center"
                  >
                    <i class="fake-circle" /><span
                      class="menu-title text-truncate"
                      data-i18n="Calls"
                    >Promotion</span>
                  </a>
                  <b-collapse :id="`collapseMenu-promotion`">
                    <ul class="menu-content">
                      <li>
                        <a
                          id="field-promotion-calls"
                          class="d-flex align-items-center"
                          @click="$router.push({ name: 'admin.calls.promotion.index' })"
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Promotion calls</span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-promotion-calls`"
                          title="Promotion calls"
                        />
                      </li>
                      <li>
                        <a
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.calls.promotion.peers-report',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Peers report</span>
                        </a>
                      </li>
                      <li>
                        <a
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.calls.promotion.pc-member-list',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >PC Members</span>
                        </a>
                      </li>
                    </ul>
                  </b-collapse>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.calls.applicant.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Applicants"
                    >Applicants</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.calls.evaluators.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Evaluators"
                    >Evaluators</span>
                  </a>
                </li> -->
              <!-- <li>
                <a
                  class="d-flex align-items-center"
                  href="../evaluators/evaluators-list.php"
                >
                  <i class="fake-circle"></i><span
                    class="menu-item text-truncate"
                    data-i18n="Promotion"
                  >Evaluators</span>
                </a>
              </li> -->
              </ul>
            </b-collapse>
          </li>
          <li
            v-if="false"
            class="nav-item has-sub"
          > <!-- CAE -->
            <a
              v-b-toggle="`collapseMenu-university`"
              class="d-flex align-items-center"
            >
              <i data-feather="message-square" /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >University</span>
            </a>
            <b-collapse :id="`collapseMenu-university`">
              <ul class="menu-content ps-2">
                <li class="">
                  <!-- active -->
                  <a
                    class="d-flex align-items-center"
                    @click="
                      $router.push({
                        name: 'admin.university.index',
                      })
                    "
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Traces"
                  >Dashboard</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li> <!-- CAE -->
          <li class="has-sub nav-item"> <!-- Users -->
            <a
              v-b-toggle="`collapseMenu-researchers`"
              class="d-flex align-items-center"
            >
              <i data-feather="user" /><span
                class="menu-title text-truncate"
                data-i18n="User"
              >Users</span>
            </a>
            <b-collapse :id="`collapseMenu-researchers`">
              <ul class="menu-content ps-2">
                <li class="">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.users.index' })"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Quick links"
                  >All users</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.calls.applicant.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Applicants"
                    >Applicants</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.calls.academy_awarded.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Awardees"
                    >Awardees</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.calls.evaluators.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Evaluators"
                    >Evaluators</span>
                  </a>
                </li>
                <li class="">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.users.index', params: { role: 'researcher' } })"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Quick links"
                  >ICREAs</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item has-sub"> <!-- Administration -->
            <a
              v-b-toggle="`collapseResearcherMenu-administration`"
              class="d-flex align-items-center"
            >
              <i data-feather="database" /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >Administration</span>
            </a>
            <b-collapse
              :id="`collapseResearcherMenu-administration`"
            >
              <ul class="menu-content ps-2">
                <li
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administrative-data'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Dedications"
                  >Administrative data</span>
                  </a>
                </li>
                <li
                  v-if="canSee('dedications')"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administration.dedications.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Dedications"
                  >Dedications</span>
                  </a>
                </li>
                <li
                  v-if="canSee('evaluator-histories')"
                  class=""
                >
                  <a
                    id="field-researcher-evaluator-host-history"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administration.evaluator-history.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Evaluator-host history</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-evaluator-host-history`"
                    title="Evaluator-host history"
                  />
                </li>
                <li
                  v-if="canSee('host-histories')"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administration.host-history.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Host history</span>
                  </a>
                </li>
                <li
                  v-if="canSee('residency-documentation')"
                  class=""
                >
                  <a
                    id="field-admin-residency-documentation"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administration.residency-documentation.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Residency documentation"
                  >Non-nationals</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-admin-residency-documentation`"
                    title="Residency documentation"
                  />
                </li>
                <li v-if="canSee('other-contracts')">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administration.other-contracts.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Other contracts"
                  >Other contracts</span>
                  </a>
                </li>
                <li
                  v-if="canSee('temporary-leaves')"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administration.temporary-leaves.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Temporary leaves"
                  >Temporary leaves</span>
                  </a>
                </li>
                <li v-if="canSee('sabbaticals')">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administration.sabbaticals.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Sabbaticals</span>
                  </a>
                </li>
                <li
                  v-if="canSee('salary-history')"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administration.salary-history.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Salary history"
                  >Salary History</span>
                  </a>
                </li>
                <li
                  v-if="canSee('supplements')"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administration.supplements.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Supplements"
                  >Supplements</span>
                  </a>
                </li>
                <li
                  v-if="canSee('unpaid-leaves')"
                  class=""
                >
                  <a
                    id="field-researcher-unpaid-leaves-of-absence"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administration.unpaid-leaves.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Unpaid leaves of absence"
                  >Unpaid leaves of absence</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-unpaid-leaves-of-absence`"
                    title="Unpaid leaves of absence"
                  />
                </li>
                <!-- <li
                  v-if="canSee('promotion-histories')"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.administration.promotion-history.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Promotion history</span>
                  </a>
                </li>
                <li v-if="canSee('payrolls')">
                  <a
                    class="d-flex align-items-center"
                    @click="checkOtpCode('researcher.administration.payrolls.index')"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Payrolls</span>
                  </a>
                </li>
                <li v-if="canSee('insurances')">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'researcher.insurances.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Insurance"
                    >Insurance</span>
                  </a>
                </li> -->
                <!-- <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'researcher.administration.other-sections.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Insurance"
                    >Other sections</span>
                  </a>
                </li> -->
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item has-sub"> <!-- Host institutions -->
            <a
              v-b-toggle="`collapseMenu-institution`"
              class="d-flex align-items-center"
            >
              <i data-feather="home" /><span
                class="menu-title text-truncate"
                data-i18n="Institution"
              >Host institutions</span>
            </a>
            <b-collapse :id="`collapseMenu-institution`">
              <ul class="menu-content ps-2">
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.institutions.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Host institutions"
                    >Hosts Institutions list</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.institution-contacts.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Institutions Contacts"
                    >Contacts</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item has-sub"> <!-- ICREA profiles -->
            <a
              v-b-toggle="`collapseMenu-profiles`"
              class="d-flex align-items-center"
            >
              <i data-feather="message-square" /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >ICREA Profile</span>
            </a>
            <b-collapse
              :id="`collapseMenu-profiles`"
            >
              <ul class="menu-content ps-2">
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.dashboard'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Academia"
                    >Dashboard</span>
                  </a>
                </li>
                <li class="">
                  <a
                    id="field-researcher-personal-data"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.personal-data.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Personal data, Biography & Research interest</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-personal-data`"
                    title="Personal data, Biography & Research interest"
                  />
                </li>
                <li v-if="canSee('research-lines')">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.research-lines.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Academia"
                    >Research lines</span>
                  </a>
                </li>
                <li v-if="canSee('educations')">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.education.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Promotion"
                    >Education</span>
                  </a>
                </li>
                <li v-if="canSee('honours-awards')">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.honours-awards.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Promotion"
                    >Honours & awards</span>
                  </a>
                </li>
                <li v-if="canSee('backgrounds')">
                  <a
                    id="field-researcher-professional-background"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.professional-background.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Promotion"
                    >Professional background</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-professional-background`"
                    title="Professional background"
                  />
                </li>
                <li v-if="canSee('postdoctoral-positions')">
                  <a
                    id="field-researcher-postdoctoral-positions"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.postdoctoral-positions.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Promotion"
                    >Postdoctoral positions</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-postdoctoral-positions`"
                    title="Postdoctoral positions"
                  />
                </li>
              </ul>
            </b-collapse>
          </li>
          <li
            v-if="canSee('grants')"
            class=" nav-item"
          >
            <a
              class="d-flex align-items-center"
              @click="$router.push({name: 'researcher.grants.index'})"
            >
              <i data-feather="mail" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >Grants & Resources</span>
            </a>
          </li> <!-- Grants -->
          <li class="nav-item has-sub"> <!-- Technology transfer -->
            <a
              v-b-toggle="`collapseResearcherMenu-tecnology`"
              class="d-flex align-items-center"
            >
              <i data-feather="message-square" /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >Technology transfer</span>
            </a>
            <b-collapse
              :id="`collapseResearcherMenu-tecnology`"
            >
              <ul class="menu-content ps-2">
                <li
                  v-if="canSee('industrial-properties')"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.industrial-property.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Industrial property</span>
                  </a>
                </li>
                <li
                  v-if="canSee('spin-offs')"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.spin-offs.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Spin-off companies</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item has-sub"> <!-- Scientific production -->
            <a
              v-b-toggle="`collapseMenu-scientific-production`"
              class="d-flex align-items-center"
            >
              <i data-feather="settings" /><span
                class="menu-title text-truncate"
              >Scientific production</span>
            </a>
            <b-collapse :id="`collapseMenu-scientific-production`">
              <ul class="menu-content ps-2">
                <li class="">
                  <!-- active -->
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.scientific-production-report.index'})"
                  >
                    <i class="fake-circle" />
                    <span
                      class="menu-item text-truncate"
                      data-i18n="Traces"
                    >
                      Scientific prod. report
                    </span>
                  </a>
                </li>
                <li class="">
                  <!-- active -->
                  <a
                    class="d-flex align-items-center"
                    @click="
                      $router.push({
                        name: 'admin.scientific-production.scientific-production-stats.index',
                      })
                    "
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Scientific production report"
                  >Scientific prod. stats</span>
                  </a>
                </li>
                <li class="">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.publications.index'})"
                  >
                    <i class="fake-circle" />
                    <span
                      class="menu-item text-truncate"
                      data-i18n="Traces"
                    >
                      Publications
                    </span>
                  </a>
                </li>
                <li class="">
                  <a
                    id="field-researcher-conferences-workshops"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.conferences.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Conferences & workshops</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-conferences-workshops`"
                    title="Conferences & workshops"
                  />
                </li>
                <li class="">
                  <a
                    id="field-researcher-courses-or-seminars"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.courses.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Courses and seminars</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-courses-or-seminars`"
                    title="Courses or seminars"
                  />
                </li>
                <li class="">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.disseminations.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Dissemination</span>
                  </a>
                </li>
                <li class="">
                  <a
                    id="field-researcher-managerial-activities"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.managerial.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Managerial activities</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-managerial-activities`"
                    title="Managerial activities"
                  />
                </li>
                <li class="">
                  <a
                    id="field-researcher-phd-master-theses-tfg"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.phds.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >PhD, Master Theses and TFG</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-phd-master-theses-tfg`"
                    title="PhD, Master Theses and TFG"
                  />
                </li>
                <li class="">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.stays-research.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Stays of research</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li
            v-if="canSee('other-sections')"
            class=" nav-item"
          >
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'researcher.administration.other-sections.index' })"
            >
              <i data-feather="map" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >Other sections</span>
            </a>
          </li>  <!-- Other sections -->
          <li class="nav-item"> <!-- Traces -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({name: 'admin.traces.index'})"
            >
              <i data-feather="pen-tool" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >Traces</span>
            </a>
          </li>
          <li class="nav-item"> <!-- Reports -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'admin.reports.index' })"
            >
              <i data-feather="book-open" /><span
                class="menu-title text-truncate"
                data-i18n="User"
              >Reports</span>
            </a>
          </li>
          <li class="nav-item has-sub"> <!-- OSH -->
            <a
              v-b-toggle="`collapseMenu-6`"
              class="d-flex align-items-center"
            >
              <i data-feather="database" /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >OSH</span>
            </a>
            <b-collapse :id="`collapseMenu-6`">
              <ul class="menu-content ps-2">
                <!-- <li class="has-sub">
                  <a
                    v-b-toggle="`collapseMenu-Cae`"
                    class="d-flex align-items-center"
                  >
                    <i class="fake-circle" /><span
                      class="menu-title text-truncate"
                      data-i18n="Calls"
                    >CAE</span>
                  </a>
                  <b-collapse :id="`collapseMenu-Cae`">
                    <ul class="menu-content">
                      <li>
                        <a
                          class="d-flex align-items-center"
                          @click="
                            $router.push({ name: 'cae.index' })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >CAE Section</span>
                        </a>
                      </li>
                      <li>
                        <a
                          class="d-flex align-items-center"
                          @click="
                            $router.push({ name: 'admin.cae.view-alerts' })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >CAE Alerts</span>
                        </a>
                      </li>
                    </ul>
                  </b-collapse>
                </li> -->
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.labour-hazards.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Insurance"
                    >OSH. Send documents</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.insurances.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Insurance"
                    >Insurance</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.tss-management.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="TSS management"
                    >TSS Management</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.change-requests.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Change requests"
                    >Change requests</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item has-sub"> <!-- Memoir -->
            <a
              v-b-toggle="`collapseMenu-memoir`"
              class="d-flex align-items-center"
            >
              <i data-feather="framer" /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >Memoir</span>
            </a>
            <b-collapse :id="`collapseMenu-memoir`">
              <ul class="menu-content ps-2">
                <li class="">
                  <a
                    class="d-flex align-items-center"
                    @click="
                      $router.push({
                        name: 'admin.memoir.index',
                      })
                    "
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Memoirs list"
                  >Memoirs list</span>
                  </a>
                </li>
                <li class="">
                  <a
                    class="d-flex align-items-center"
                    @click="
                      $router.push({
                        name: 'researcher.memoir-profile.index',
                      })
                    "
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Memoirs profiles"
                  >Memoirs profiles</span>
                  </a>
                </li>
                <li class="">
                  <a
                    class="d-flex align-items-center"
                    @click="
                      $router.push({
                        name: 'researcher.memoir-highlight.index',
                      })
                    "
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Memoirs highlights"
                  >Memoirs highlights</span>
                  </a>
                </li>
                <li class="">
                  <a
                    class="d-flex align-items-center"
                    @click="
                      $router.push({
                        name: 'memoir-exports.index',
                      })
                    "
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Memoirs highlights"
                  >Memoirs exports</span>
                  </a>
                </li>
                <li
                  v-if="convo && convo.id"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.users.index', params: { onPromotion: true }})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Quick links"
                  >Users active Memoir</span>
                  </a>
                </li>
                <!-- <li class="">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.users.index', query: { memoir: (year - 1) } })"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Quick links"
                  >Users active Memoir {{ (year - 1) }}</span>
                  </a>
                </li> -->
              </ul>
            </b-collapse>
          </li>
          <li class="navigation-header">
            <span data-i18n="Configuration">Funcionalities</span>
          </li>
          <li class="nav-item"> <!-- Mail logger -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'admin.mail-logger.index' })"
            >
              <i data-feather="mail" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >Mail logger</span>
            </a>
          </li>
          <li class="nav-item"> <!-- Request forms -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'admin.request-forms.index' })"
            >
              <i data-feather="file-text" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >Request forms</span>
            </a>
          </li>
          <li class="nav-item"> <!-- Knowledge base -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'knowledge-base.index' })"
            >
              <i data-feather="book-open" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >Knowledge base</span>
            </a>
          </li>
          <li class="nav-item"> <!-- Quick links -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'favorites' })"
            >
              <i data-feather="star" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >Quick links</span>
            </a>
          </li>
          <li class="nav-item"> <!-- Mail templates -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'admin.mail-templates.index' })"
            >
              <i data-feather="mail" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >Mail templates</span>
            </a>
          </li>
          <li class="nav-item has-sub"> <!-- Masters -->
            <a
              v-b-toggle="`collapseMenu-1`"
              class="d-flex align-items-center"
            >
              <i data-feather="database" /><span
                class="menu-title text-truncate"
                data-i18n="Masters"
              >Masters</span>
            </a>
            <b-collapse :id="`collapseMenu-1`">
              <ul class="menu-content ps-2">
                <li class="has-sub">
                  <a
                    v-b-toggle="`collapseMenu-activity`"
                    class="d-flex align-items-center"
                  >
                    <i class="fake-circle" /><span
                      class="menu-title text-truncate"
                      data-i18n="Calls"
                    >Activities</span>
                  </a>
                  <b-collapse :id="`collapseMenu-activity`">
                    <ul class="menu-content">
                      <li>
                        <a
                          id="field-activities-conferences"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({ name: 'admin.activity-types.index' })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Conferences - Type of participation</span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-activities-conferences`"
                          title="Conferences - Type of participation"
                        />
                      </li>
                      <li>
                        <a
                          id="field-dissmention"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.dissemination-types.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Dissemination - Type of participation
                          </span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-dissmention`"
                          title="Dissmention - Type of participation"
                        />
                      </li>
                      <!-- <li>
                      <a
                        class="d-flex align-items-center"
                        @click="$router.push({name: 'admin.status-authorization.index'})"
                      >
                        <i class="fake-circle"></i><span
                          class="menu-item text-truncate"
                          data-i18n="Phrases"
                        >Status authorization</span>
                      </a>
                    </li> -->
                      <li>
                        <a
                          id="field-managerial-type-of-activity"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({ name: 'admin.managerial-types.index' })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Managerial - Type of activity
                          </span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-managerial-type-of-activity`"
                          title="Managerial - Type of activity"
                        />
                      </li>
                      <li>
                        <a
                          id="field-mentoring-roles"
                          class="d-flex align-items-center"
                          @click="$router.push({ name: 'admin.roles-phd.index' })"
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Mentoring - Roles</span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-mentoring-roles`"
                          title="Mentoring - roles"
                        />
                      </li>
                      <li>
                        <a
                          id="field-mentoring-type-of-degree"
                          class="d-flex align-items-center"
                          @click="$router.push({ name: 'admin.types-phd.index' })"
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Mentoring - Type of degree
                          </span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-mentoring-type-of-degree`"
                          title="Mentoring - Type of degree"
                        />
                      </li>
                      <li>
                        <a
                          id="field-stay-of-reseach-type-of-stay"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.stay-research-types.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Stay of Reseach - Type of stay</span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-stay-of-reseach-type-of-stay`"
                          title="Stay of Reseach - Type of stay"
                        />
                      </li>
                      <li>
                        <a
                          id="field-teaching-type-of-courses"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({ name: 'admin.course-types.index' })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Teaching - Type of courses</span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-teaching-type-of-courses`"
                          title="Teaching - Type of courses"
                        />
                      </li>
                    </ul>
                  </b-collapse>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.areas-groups.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Areas & Groups"
                    >Areas & Groups</span>
                  </a>
                </li>
                <!-- <li>
                <a
                  class="d-flex align-items-center"
                  @click="$router.push({ name: 'admin.access-calls.index' })"
                >
                  <i class="fake-circle"></i><span
                    class="menu-item text-truncate"
                    data-i18n="Areas & Groups"
                  >Calls access</span>
                </a>
              </li> -->
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'admin.card-types.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Insurance types"
                    >Card types</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.contract-types.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Phrases"
                    >Contract types</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'admin.dedication-types.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Temporary leave types"
                    >Dedication types</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="
                      $router.push({ name: 'admin.education-levels.index' })
                    "
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Phrases"
                    >Education levels</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'admin.erc.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="ERC"
                    >ERC</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'admin.genres.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Genres"
                    >Genders</span>
                  </a>
                </li>
                <li class="has-sub">
                  <a
                    v-b-toggle="`collapseMenu-grants`"
                    class="d-flex align-items-center"
                  >
                    <i class="fake-circle" /><span
                      class="menu-title text-truncate"
                      data-i18n="Calls"
                    >Grants</span>
                  </a>
                  <b-collapse :id="`collapseMenu-grants`">
                    <ul class="menu-content">
                      <li>
                        <a
                          class="d-flex align-items-center"
                          @click="
                            $router.push({ name: 'admin.grants-positions.index' })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Positions"
                          >Positions</span>
                        </a>
                      </li>
                      <li>
                        <a
                          id="field-type-of-funding"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({ name: 'admin.types-of-funding.index' })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Types of Funding"
                          >Types of funding</span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-type-of-funding`"
                          title="Type of funding"
                        />
                      </li>
                      <li>
                        <a
                          id="field-type-of-personnel"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.types-of-personnel.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Types of personnel"
                          >Types of personnel</span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-type-of-personnel`"
                          title="Type of personnel"
                        />
                      </li>
                      <li>
                        <a
                          id="field-types-of-resource"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.types-of-resource.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Types of Resource"
                          >Types of resource</span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-types-of-resource`"
                          title="Types of resource"
                        />
                      </li>
                    </ul>
                  </b-collapse>
                </li>
                <li class="has-sub">
                  <a
                    v-b-toggle="`collapseMenu-8`"
                    class="d-flex align-items-center"
                  >
                    <i class="fake-circle" /><span
                      class="menu-title text-truncate"
                      data-i18n="Calls"
                    >Host institutions</span>
                  </a>
                  <b-collapse :id="`collapseMenu-8`">
                    <ul class="menu-content">
                      <li>
                        <a
                          id="field-department-types"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.institution-departments.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Department types</span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-department-types`"
                          title="Department types"
                        />
                      </li>
                      <li>
                        <a
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.institution-types.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Hosts types</span>
                        </a>
                      </li>
                      <li>
                        <a
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.institution-positions.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Positions</span>
                        </a>
                      </li>
                    </ul>
                  </b-collapse>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'admin.icrea-status.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="ICREA status"
                    >ICREA status</span>
                  </a>
                </li>
                <li>
                  <a
                    id="field-industrial-property-types"
                    class="d-flex align-items-center"
                    @click="
                      $router.push({
                        name: 'admin.industrial-property-parent-types.index',
                      })
                    "
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Phrases"
                    >Industrial property types</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-industrial-property-types`"
                    title="Industrial property types"
                  />
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.insurance-types.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Insurance types"
                    >Insurance types</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.mail-templates.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Mail templates"
                    >Mail templates</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'admin.modal-texts.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Modal texts"
                    >Modal texts</span>
                  </a>
                </li>
                <li class="has-sub">
                  <a
                    v-b-toggle="`collapseMenu-4`"
                    class="d-flex align-items-center"
                  >
                    <i class="fake-circle" /><span
                      class="menu-title text-truncate"
                      data-i18n="Calls"
                    >OSH Masters</span>
                  </a>
                  <b-collapse :id="`collapseMenu-4`">
                    <ul class="menu-content">
                      <li>
                        <a
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.ocupational-hazard.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Hazards</span>
                        </a>
                      </li>
                      <li>
                        <a
                          id="field-ocupational-safety"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.ocupational-safety.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >OSH</span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-ocupational-safety`"
                          title="Ocupation Safety"
                        />
                      </li>
                      <li>
                        <a
                          class="d-flex align-items-center"
                          @click="
                            $router.push({ name: 'admin.workplaces.index' })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Workplaces</span>
                        </a>
                      </li>
                    </ul>
                  </b-collapse>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="
                      $router.push({ name: 'admin.payroll-versions.index' })
                    "
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Phrases"
                    >Payrolls version</span>
                  </a>
                </li>
                <li>
                  <a
                    id="field-postdoctoral-positions"
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.positions.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Phrases"
                    >Postdoctoral positions</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-postdoctoral-positions`"
                    title="Postdoctoral positions"
                  />
                </li>
                <!-- <li class="has-sub">
                <a
                  v-b-toggle="`collapseMenu-ipa`"
                  class="d-flex align-items-center"
                >
                  <i class="fake-circle" /><span
                    class="menu-title text-truncate"
                    data-i18n="Calls"
                  >Industrial prop...</span>
                </a>
                <b-collapse :id="`collapseMenu-ipa`">
                  <ul class="menu-content">
                    <li>
                      <a
                        class="d-flex align-items-center"
                        @click="
                          $router.push({
                            name: 'admin.industrial-property-parent-types.index',
                          })
                        "
                      >
                        <i class="fake-circle" /><span
                          class="menu-item text-truncate"
                          data-i18n="Phrases"
                        >Types</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="d-flex align-items-center"
                        @click="
                          $router.push({
                            name: 'admin.industrial-property-types.index',
                          })
                        "
                      >
                        <i class="fake-circle" /><span
                          class="menu-item text-truncate"
                          data-i18n="Phrases"
                        >Types</span>
                      </a>
                    </li>
                  </ul>
                </b-collapse>
              </li> -->
                <!-- <li>
                <a
                  class="d-flex align-items-center"
                  @click="$router.push({name: 'admin.patent-status.index'})"
                >
                  <i class="fake-circle"></i><span
                    class="menu-item text-truncate"
                    data-i18n="Phrases"
                  >Patents Status</span>
                </a>
              </li> -->
                <li class="has-sub">
                  <a
                    v-b-toggle="`collapseMenu-publication-types`"
                    class="d-flex align-items-center"
                  >
                    <i class="fake-circle" /><span
                      class="menu-title text-truncate"
                      data-i18n="Calls"
                    >Publications</span>
                  </a>
                  <b-collapse :id="`collapseMenu-publication-types`">
                    <ul class="menu-content">
                      <li>
                        <a
                          id="field-publication-stages"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.publication-stages.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Stages</span>
                        </a>
                      </li>
                      <li>
                        <a
                          id="field-publication-status"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.publication-status.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Status</span>
                        </a>
                      </li>
                      <li>
                        <a
                          id="field-publications-types"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.publication-types.index',
                            })
                          "
                        >
                          <i class="fake-circle" /><span
                            class="menu-item text-truncate"
                            data-i18n="Phrases"
                          >Types</span>
                        </a>
                      </li>
                    </ul>
                  </b-collapse>
                </li>
                <li class="nav-item has-sub">
                  <a
                    :id="`field-residency`"
                    v-b-toggle="`collapseMenu-residency-documentation`"
                    class="d-flex align-items-center"
                  >
                    <i class="fake-circle" /><span
                      class="menu-title text-truncate"
                      data-i18n="Calls"
                    >Residency documentation</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-residency`"
                    title="Residency documentation"
                  />
                  <b-collapse :id="`collapseMenu-residency-documentation`">
                    <ul class="menu-content ps-2">
                      <li class="">
                        <!-- active -->
                        <a
                          :id="`field-residency-authorization`"
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.residency-documentation.authorization.index',
                            })
                          "
                        ><i class="fake-circle" /><span
                          class="menu-item text-truncate"
                          data-i18n="Senior Call"
                        >Authorizations</span>
                        </a>
                        <b-tooltip
                          placement="right"
                          boundary="document"
                          triggers="hover"
                          :target="`field-residency-authorization`"
                          title="Authorizations"
                        />
                      </li>
                      <li class="">
                        <!-- active -->
                        <a
                          class="d-flex align-items-center"
                          @click="
                            $router.push({
                              name: 'admin.residency-documentation.stage.index',
                            })
                          "
                        ><i class="fake-circle" /><span
                          class="menu-item text-truncate"
                          data-i18n="Senior Call"
                        >Stages</span>
                        </a>
                      </li>
                    </ul>
                  </b-collapse>
                </li>
                <li>
                  <a
                    id="field-status-management"
                    class="d-flex align-items-center"
                    @click="
                      $router.push({ name: 'admin.status-management.index' })
                    "
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Phrases"
                    >Status management</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-status-management`"
                    title="Status management"
                  />
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'admin.supplement-types.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Supplement types"
                    >Supplement types</span>
                  </a>
                </li>
                <li>
                  <a
                    id="field-temporary-leave-types"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'admin.temporary-leave-types.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Temporary leave types"
                    >Temporary leave types</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-temporary-leave-types`"
                    title="Temporary leave types"
                  />
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'admin.trace-typologies.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Insurance types"
                    >Trace typologies</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'admin.type-of-leaves.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Type of leaves"
                    >Type of leaves</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.users.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Users"
                    >Users</span>
                  </a>
                </li>
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'admin.user-permission-payrolls.index' })"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate"
                      data-i18n="Users permission payrolls"
                    >Users permissions payrolls</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="navigation-header">
            <span data-i18n="Configuration">Configuration</span>
          </li>
          <li class="nav-item"> <!-- System config -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'admin.system-config.index' })"
            >
              <i data-feather="settings" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >System config</span>
            </a>
          </li>
          <li class="nav-item"> <!-- Phrases -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'admin.phrases.index' })"
            >
              <i data-feather="align-center" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >Phrases</span>
            </a>
          </li>
          <li class="nav-item"> <!-- Roles -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'admin.roles.index' })"
            >
              <i data-feather="users" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >Roles</span>
            </a>
          </li>
          <li class="nav-item"> <!-- Social networks -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'admin.social-network.index' })"
            >
              <i data-feather="twitter" /><span
                class="menu-title text-truncate"
                data-i18n="Contacts"
              >Social networks</span>
            </a>
          </li>
          <li class=""> <!-- Texts managements -->
            <a
              id="field-texts-managements"
              class="d-flex align-items-center"
              @click="$router.push({ name: 'admin.section-labels.index' })"
            >
              <i data-feather="file-text" /><span
                class="menu-title text-truncate"
                data-i18n="Texts managements"
              >Texts managements</span>
            </a>
            <b-tooltip
              placement="right"
              boundary="document"
              triggers="hover"
              :target="`field-texts-managements`"
              title="Texts managements"
            />
          </li>
        </ul>
      </div>
    </div>
    <!-- END: Main Menu-->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { BCollapse, BTooltip } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
    BTooltip,
  },
  data() {
    return {
      convo: null,
      year: null,
    }
  },
  computed: {
    ...mapGetters({
      show: 'modals/showAdminMenu',
      openMenu: 'modals/showEvaluatorMenu',
      user: 'auth/admin',
    }),
  },
  watch: {
    show() {
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
  async mounted() {
    this.year = new Date().getFullYear()
    const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/get-promotion-active`)
    this.convo = resp.data
  },
  methods: {
    canSee(section) {
      return this.user.roles.includes('super-admin') || this.user.permissions.includes(`read ${section}`)
    },
  },
}
</script>
