<template>
  <!-- BEGIN: Main Menu-->
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow expanded menu-dark"
    :style="openMenu ? 'transform: translate3d(260px, 0, 0);width: 260px;left: -260px;opacity:1;' : ''"
    data-scroll-to-active="true"
  >
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item me-auto">
          <a
            class="navbar-brand"
            href="#"
          >
            <img
              :src="require('@/assets/images/logo/logo-icrea-white-menu.svg')"
              alt="ICREA"
            >
          </a>
        </li>
        <li class="nav-item nav-toggle">
          <a
            class="nav-link modern-nav-toggle pe-0"
            data-bs-toggle="collapse"
          >
            <i
              class="d-block d-xl-none text-primary toggle-icon font-medium-4"
              data-feather="x"
            />
            <i
              class="d-none d-xl-block collapse-toggle-icon font-medium-4  text-primary"
              data-feather="disc"
              data-ticon="disc"
            />
          </a>
        </li>
      </ul>
    </div>
    <div class="main-menu-content overflow-auto">
      <div
        v-if="fakeUsers && fakeUsers.length > 0 && user.roles[0] != 'super-admin'"
        class="back__menu"
      >
        <a
          title="back to the admin menu"
          @click="returnoToUser()"
        ><i data-feather="chevron-left" /> Back to admin menu</a>
      </div>
      <div class="researcher__menu__profile">
        <p>PROFILE MENU</p>
        <div class="avatar avatar-custom">
          <img
            :src="icon_path ? icon_path : require('@/assets/images/avatars/user_default.png')"
            alt="avatar"
            width="32"
            height="32"
          >
        </div>
        {{ user.name }}
      </div>
      <ul
        id="main-menu-navigation"
        class="navigation navigation-main"
        data-menu="menu-navigation"
      >
        <template v-if="!user.roles.includes('publication-admin')">
          <li class=" nav-item">
            <a
              class="d-flex align-items-center"
              @click="$router.push({name: 'researcher.dashboard'})"
            >
              <i data-feather="home" /><span
                class="menu-title text-truncate"
                data-i18n="Dashboard"
              >Dashboard</span>
            </a>
          </li>
          <li class="nav-item"> <!-- Alerts -->
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'alerts' })"
            >
              <i data-feather="bell" /><span
                class="menu-title text-truncate"
              >Alerts & notifications</span>
            </a>
          </li>
          <li
            v-if="convoTitle"
            class=" nav-item"
          >
            <a
              class="d-flex align-items-center"
              @click="$router.push({name: 'researcher.promotion-call.index'})"
            >
              <i
                data-feather="bell"
                class="text-danger"
              /><span
                class="menu-title text-truncate"
                data-i18n="Dashboard"
              >{{ convoTitle }}</span><span class="badge bg-danger">{{ days }} days</span>
            </a>
          </li>
          <li
            v-if="convoData.promotion && (convoData.promotion.assignPeers || convoData.promotion.evaluate)"
            class="nav-item has-sub"
          >
            <a
              v-b-toggle="`collapseMenu-4`"
              class="d-flex align-items-center"
            >
              <i
                data-feather="bell"
                class="text-danger"
              /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >{{ convoData.promotion.title }}</span>
            </a>
            <b-collapse :id="`collapseMenu-4`">
              <ul class="menu-content">
                <li
                  v-if="convoData.promotion.assignPeers"
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'evaluator.calls.evaluations.assign-peers' })"
                  ><i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Assign peers</span><span class="ms-50 badge bg-danger">{{ convoData.promotion.days }} days</span>
                  </a>
                </li>
                <li
                  v-if="convoData.promotion.evaluate"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'evaluator.calls.promotion.evaluations' })"
                  ><i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Evaluations</span><span class="ms-50 badge bg-danger">{{ convoData.promotion.days }} days</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li
            v-if="convoData.academia"
            class="nav-item has-sub"
          >
            <a
              v-b-toggle="`collapseMenu-5`"
              class="d-flex align-items-center"
            >
              <i
                data-feather="bell"
                class="text-danger"
              /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >{{ convoData.academia.title }}</span>
            </a>
            <b-collapse :id="`collapseMenu-5`">
              <ul class="menu-content">
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'evaluator.calls.evaluations' })"
                  ><i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Evaluations</span><span class="ms-50 badge bg-danger">{{ convoData.academia.days }} days</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li
            v-if="memoirProfileId || highlights"
            class="nav-item has-sub"
          >
            <a
              v-b-toggle="`collapseResearcherMenu-99`"
              class="d-flex align-items-center"
            >
              <i
                data-feather="bell"
                class="text-danger"
              /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >{{ convoTitleMemoir }}</span><span
                v-if="daysRemaining"
                class="ms-50 badge bg-danger"
              >{{ daysRemaining }} days</span>
            </a>
            <b-collapse
              :id="`collapseResearcherMenu-99`"
              visible
            >
              <ul class="menu-content ps-2">
                <li
                  v-if="memoirProfileId"
                  class=""
                >
                  <a
                    id="field-researcher-personal-data"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.memoir-view.view', params: { id: memoirProfileId }})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate ms-1"
                    data-i18n="Senior Call"
                  >Profile</span>
                  </a>
                </li>
                <li
                  class=""
                >
                  <a
                    id="field-researcher-personal-data"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.memoir-highlight.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate ms-1"
                    data-i18n="Senior Call"
                  >Highlights</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item has-sub">
            <a
              v-b-toggle="`collapseResearcherMenu-1`"
              class="d-flex align-items-center"
            >
              <i data-feather="message-square" /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >Profile</span>
            </a>
            <b-collapse
              :id="`collapseResearcherMenu-1`"
            >
              <ul class="menu-content ps-2">
                <li class="">
                  <a
                    id="field-researcher-personal-data"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.personal-data.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate ms-1"
                    data-i18n="Senior Call"
                  >Personal data, Biography & Research interest</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-personal-data`"
                    title="Personal data, Biography & Research interest"
                  />
                </li>
                <li v-if="canSee('research-lines')">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.research-lines.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate ms-1"
                      data-i18n="Academia"
                    >Research lines</span>
                  </a>
                </li>
                <li v-if="canSee('backgrounds')">
                  <a
                    id="field-researcher-professional-background"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.professional-background.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate ms-1"
                      data-i18n="Promotion"
                    >Professional background</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-professional-background`"
                    title="Professional background"
                  />
                </li>
                <li v-if="canSee('postdoctoral-positions')">
                  <a
                    id="field-researcher-postdoctoral-positions"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.postdoctoral-positions.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate ms-1"
                      data-i18n="Promotion"
                    >Postdoctoral positions</span>
                  </a>
                  <b-tooltip
                    placement="right"
                    boundary="document"
                    triggers="hover"
                    :target="`field-researcher-postdoctoral-positions`"
                    title="Postdoctoral positions"
                  />
                </li>
                <li v-if="canSee('educations')">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.education.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate ms-1"
                      data-i18n="Promotion"
                    >Education</span>
                  </a>
                </li>
                <li v-if="canSee('honours-awards')">
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.honours-awards.index'})"
                  >
                    <i class="fake-circle" /><span
                      class="menu-item text-truncate ms-1"
                      data-i18n="Promotion"
                    >Honours & awards</span>
                  </a>
                </li>
              <!-- <li>
                <a
                  class="d-flex align-items-center"
                  href="../researcher/researcher-files.php"
                >
                  <i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Promotion"
                  >Files maintenence</span>
                </a>
              </li> -->
              <!-- <li>
                <a
                  class="d-flex align-items-center"
                  href="#"
                >
                  <i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Promotion"
                  >Other sections</span>
                </a>
              </li> -->
              </ul>
            </b-collapse>
          </li>
        </template>
        <li class="nav-item has-sub">
          <a
            v-b-toggle="`collapseResearcherMenu-99`"
            class="d-flex align-items-center"
          >
            <i data-feather="settings" /><span
              class="menu-title text-truncate"
              data-i18n="Calls"
            >Scientific production</span>
          </a>
          <b-collapse
            :id="`collapseResearcherMenu-99`"
          >
            <ul class="menu-content ps-2">
              <li class="">
                <a
                  id="field-researcher-personal-data"
                  class="d-flex align-items-center"
                  @click="$router.push({name: 'researcher.scientific-production-report.index'})"
                ><i class="fake-circle" /><span
                  class="menu-item text-truncate ms-1"
                  data-i18n="Senior Call"
                >My Scientific Production</span>
                </a>
              </li>
              <li
                v-if="canSee('publications')"
                class=""
              >
                <a
                  id="field-researcher-personal-data"
                  class="d-flex align-items-center"
                  @click="$router.push({name: 'researcher.publications.index'})"
                ><i class="fake-circle" /><span
                  class="menu-item text-truncate ms-1"
                  data-i18n="Senior Call"
                >Publications</span>
                </a>
              </li>
              <li
                v-if="canSee('activity')"
                class=""
              >
                <a
                  id="field-researcher-conferences-workshops"
                  class="d-flex align-items-center"
                  @click="$router.push({name: 'researcher.conferences.index'})"
                ><i class="fake-circle" /><span
                  class="menu-item text-truncate ms-1"
                  data-i18n="Senior Call"
                >Conferences & workshops</span>
                </a>
                <b-tooltip
                  placement="right"
                  boundary="document"
                  triggers="hover"
                  :target="`field-researcher-conferences-workshops`"
                  title="Conferences & workshops"
                />
              </li>
              <li
                v-if="canSee('activity')"
                class=""
              >
                <a
                  id="field-researcher-courses-or-seminars"
                  class="d-flex align-items-center"
                  @click="$router.push({name: 'researcher.courses.index'})"
                ><i class="fake-circle" /><span
                  class="menu-item text-truncate ms-1"
                  data-i18n="Senior Call"
                >Courses and seminars</span>
                </a>
                <b-tooltip
                  placement="right"
                  boundary="document"
                  triggers="hover"
                  :target="`field-researcher-courses-or-seminars`"
                  title="Courses or seminars"
                />
              </li>
              <li
                v-if="canSee('activity')"
                class=""
              >
                <a
                  class="d-flex align-items-center"
                  @click="$router.push({name: 'researcher.disseminations.index'})"
                ><i class="fake-circle" /><span
                  class="menu-item text-truncate ms-1"
                  data-i18n="Senior Call"
                >Dissemination</span>
                </a>
              </li>
              <li
                v-if="canSee('activity')"
                class=""
              >
                <a
                  id="field-researcher-managerial-activities"
                  class="d-flex align-items-center"
                  @click="$router.push({name: 'researcher.managerial.index'})"
                ><i class="fake-circle" /><span
                  class="menu-item text-truncate ms-1"
                  data-i18n="Senior Call"
                >Managerial activities</span>
                </a>
                <b-tooltip
                  placement="right"
                  boundary="document"
                  triggers="hover"
                  :target="`field-researcher-managerial-activities`"
                  title="Managerial activities"
                />
              </li>
              <li
                v-if="canSee('activity')"
                class=""
              >
                <a
                  id="field-researcher-phd-master-theses-tfg"
                  class="d-flex align-items-center"
                  @click="$router.push({name: 'researcher.phds.index'})"
                ><i class="fake-circle" /><span
                  class="menu-item text-truncate ms-1"
                  data-i18n="Senior Call"
                >PhD, Master Theses and TFG</span>
                </a>
                <b-tooltip
                  placement="right"
                  boundary="document"
                  triggers="hover"
                  :target="`field-researcher-phd-master-theses-tfg`"
                  title="PhD, Master Theses and TFG"
                />
              </li>
              <li
                v-if="canSee('activity')"
                class=""
              >
                <a
                  class="d-flex align-items-center"
                  @click="$router.push({name: 'researcher.stays-research.index'})"
                ><i class="fake-circle" /><span
                  class="menu-item text-truncate ms-1"
                  data-i18n="Senior Call"
                >Stays of research</span>
                </a>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li
          v-if="canSee('grants')"
          class=" nav-item"
        >
          <a
            class="d-flex align-items-center"
            @click="$router.push({name: 'researcher.grants.index'})"
          >
            <i data-feather="mail" /><span
              class="menu-title text-truncate"
              data-i18n="Contacts"
            >Grants & Resources</span>
          </a>
        </li>
        <template v-if="!user.roles.includes('publication-admin')">
          <li class="nav-item has-sub">
            <a
              v-b-toggle="`collapseResearcherMenu-tecnology`"
              class="d-flex align-items-center"
            >
              <i data-feather="message-square" /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >Technology transfer</span>
            </a>
            <b-collapse
              :id="`collapseResearcherMenu-tecnology`"
            >
              <ul class="menu-content ps-2">
                <li
                  v-if="canSee('industrial-properties')"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.industrial-property.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate ms-1"
                    data-i18n="Senior Call"
                  >Industrial property</span>
                  </a>
                </li>
                <li
                  v-if="canSee('spin-offs')"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.spin-offs.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate ms-1"
                    data-i18n="Senior Call"
                  >Spin-off companies</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li
            v-if="canSee('memoirs')"
            class="nav-item has-sub"
          >
            <a
              v-b-toggle="`collapseResearcherMenu-memoir`"
              class="d-flex align-items-center"
            >
              <i data-feather="trello" /><span
                class="menu-title text-truncate"
                data-i18n="ICREA Memoir"
              >ICREA Memoir</span>
            </a>
            <b-collapse
              :id="`collapseResearcherMenu-memoir`"
            >
              <ul class="menu-content ps-2">
                <li
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.memoir.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate ms-1"
                    data-i18n="Profiles"
                  >Profiles</span>
                  </a>
                </li>
                <li
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.memoir-highlight.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate ms-1"
                    data-i18n="Highlights"
                  >Highlights</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
        </template>
        <li
          v-if="canSee('other-sections')"
          class=" nav-item"
        >
          <a
            class="d-flex align-items-center"
            @click="$router.push({ name: 'researcher.administration.other-sections.index' })"
          >
            <i data-feather="map" /><span
              class="menu-title text-truncate"
              data-i18n="Contacts"
            >Other sections</span>
          </a>
        </li>
        <li class="navigation-header">
          <span data-i18n="Configuration">Administration</span>
        </li>
        <li
          class="nav-item"
        >
          <a
            class="d-flex align-items-center"
            @click="$router.push({name: 'researcher.administrative-data'})"
          >
            <i data-feather="package" /><span
              class="menu-title text-truncate"
              data-i18n="Administrative data"
            >Administrative data</span>
          </a>
        </li>
        <li v-if="canSee('other-contracts')">
          <a
            class="d-flex align-items-center"
            @click="$router.push({name: 'researcher.administration.other-contracts.index'})"
          ><i data-feather="file" /><span
            class="menu-item text-truncate"
            data-i18n="Other contracts"
          >Other contracts</span>
          </a>
        </li>
        <li v-if="canSee('payrolls')">
          <a
            class="d-flex align-items-center"
            @click="checkOtpCode('researcher.administration.payrolls.index')"
          ><i data-feather="dollar-sign" /><span
            class="menu-item text-truncate"
            data-i18n="Senior Call"
          >Payrolls</span>
          </a>
        </li>
        <li v-if="canSee('sabbaticals')">
          <a
            class="d-flex align-items-center"
            @click="$router.push({name: 'researcher.administration.sabbaticals.index'})"
          ><i data-feather="calendar" /><span
            class="menu-item text-truncate"
            data-i18n="Senior Call"
          >Sabbaticals</span>
          </a>
        </li>
        <li class="nav-item has-sub">
          <a
            v-b-toggle="`collapseResearcherMenu-11`"
            class="d-flex align-items-center"
          >
            <i data-feather="message-square" /><span
              class="menu-title text-truncate"
              data-i18n="Calls"
            >OSH</span>
          </a>
          <b-collapse
            :id="`collapseResearcherMenu-11`"
          >
            <ul class="menu-content ps-2">
              <li class="">
                <a
                  id="field-researcher-osh"
                  class="d-flex align-items-center"
                  @click="$router.push({name: 'researcher.occupational-hazards.index'})"
                >
                  <i class="fake-circle" /><span
                    class="menu-title text-truncate"
                  >Occupational Safety and Health (OSH)</span>
                </a>
                <b-tooltip
                  placement="right"
                  boundary="document"
                  triggers="hover"
                  :target="`field-researcher-osh`"
                  title="Occupational Safety and Health (OSH)"
                />
              </li>
              <li v-if="canSee('insurances')">
                <a
                  class="d-flex align-items-center"
                  @click="$router.push({ name: 'researcher.insurances.index' })"
                >
                  <i class="fake-circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Insurance"
                  >Insurance</span>
                </a>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="navigation-header">
          <span data-i18n="Configuration">Functionalities</span>
        </li>
        <li class="nav-item"> <!-- Knowledge base -->
          <a
            class="d-flex align-items-center"
            @click="$router.push({ name: 'knowledge-base.index' })"
          >
            <i data-feather="book-open" /><span
              class="menu-title text-truncate"
              data-i18n="Contacts"
            >Knowledge base</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
<!-- END: Main Menu-->
</template>

<script>
import { BCollapse, BTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import OTP from './modals/OTP.vue'

export default {
  components: {
    BCollapse,
    BTooltip,
    OTP,
  },
  data() {
    return {
      days: null,
      convoTitle: null,
      convoTitleMemoir: null,
      memoirProfileId: null,
      daysRemaining: null,
      highlights: null,
      icon_path: '',
      convoData: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/admin',
      fakeUsers: 'auth/fakeUsers',
      activeCode: 'auth/activeCode',
      openMenu: 'modals/showEvaluatorMenu',
    }),
  },
  async mounted() {
    const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/has-promotion-active`)
    this.icon_path = this.user ? this.user.avatarUrl : ''

    if (resp.data.title) {
      this.days = !resp.data.evaluate ? resp.data.days : 0
      this.convoTitle = resp.data.title
    }

    Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs/getActiveMemoir`).then(e => {
      if (e.data.title) {
        this.convoTitleMemoir = e.data.title
        this.memoirProfileId = e.data.profile_id ? e.data.profile_id.id : null
        this.highlights = e.data.highlights
        this.daysRemaining = e.data.days_remaining
      }
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    })

    if (this.user.roles_all.some(e => e.evaluator === true)) {
      Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/has-active-calls`).then(res => {
        this.convoData = res.data
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      })
    }
  },
  methods: {
    returnoToUser() {
      window.location.href = this.$router.currentRoute.path
    },
    canSee(section) {
      return this.user.roles.includes('super-admin') || this.user.permissions.includes(`read ${section}`)
    },
    async checkOtpCode(routeName) {
      await this.$store.dispatch('auth/fetchActiveCode', this.user.id)
      if (this.activeCode !== 'OK') {
        await this.$store.dispatch('modals/saveOtpData', { route: routeName, type: 'route' })
        this.$store.dispatch('modals/showOtpModal', true)
      } else {
        this.$router.push({ name: routeName })
      }
    },
  },
}
</script>
